import styled from 'styled-components'

const DemoSelectorStyles = styled.div`
    a {
        &:hover {
            background-color: var(--bs-success);
            color: var(--bs-white);
        }
        &[aria-current='page'],
        &.active {
            background-color: var(--bs-success);
            color: var(--bs-white);
        }
    }
`

export default DemoSelectorStyles
