import { Container } from 'react-bootstrap'
import { Link } from 'gatsby'
import React from 'react'
import DemoSelectorStyles from '../styles/DemoSelectorStyles'
import NumberedRow from './Layout/NumberedRow'

/**
 * @returns {JSX.Element}
 * @constructor
 */
const DemoSelector = () => (
    <DemoSelectorStyles>
        <section className='py-5'>
            <Container>
                <NumberedRow number={1}>
                    <h2 className='h1 mb-3'>Choose demo version</h2>
                    <p className='mb-4'>
                        See the view for first-time users - easy and fun! No cash, app downloads, or cards are needed.
                        Some country-specific changes can have minor variations to the end-user flow.
                    </p>

                    <div className='d-grid gap-2 d-md-block'>
                        <SelectorLink to={'/'} name='Pay with Siru' />
                        <SelectorLink to={'/trial-notification'} name='Trial notification' />
                        <SelectorLink to={'/trial-expired'} name='Trial expired' />
                    </div>
                </NumberedRow>
            </Container>
        </section>
    </DemoSelectorStyles>
)

const SelectorLink = ({ to, name }) => (
    <Link to={to} className='btn btn-outline-dark fs-6 rounded-0 spaced-light px-5 mb-2 me-3'>
        {name}
    </Link>
)

export default DemoSelector
