import React from 'react'
import { Card, Container } from 'react-bootstrap'
import NumberedRow from './Layout/NumberedRow'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import { Helmet } from 'react-helmet'

/**
 * @returns {JSX.Element}
 * @constructor
 */
const ContactForm = () => {
    const { image } = useStaticQuery(graphql`
        query {
            image: file(relativePath: { eq: "contact-section-bg.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 3840
                        height: 500
                        placeholder: BLURRED
                        quality: 80
                        transformOptions: { cropFocus: CENTER, fit: OUTSIDE }
                    )
                }
            }
        }
    `)

    const bgImage = getImage(image.childImageSharp)

    return (
        <>
            <Helmet>
                <script src='https://webforms.pipedrive.com/f/loader' />
            </Helmet>
            <BgImage tag='section' image={bgImage} className='py-5 darker-background-overlay'>
                <Container>
                    <NumberedRow number={4} numberContainerClassName='bg-success text-white border-0'>
                        <Card body className='border-0 py-5'>
                            <div
                                className='pipedriveWebForms'
                                data-pd-webforms='https://webforms.pipedrive.com/f/m1fXT1MMcUcKaoaRLP6RQPDKb8NBQRidF6kkESMtKxSgkK4jRM84Blye8j5LBuz'
                            />
                        </Card>
                    </NumberedRow>
                </Container>
            </BgImage>
        </>
    )
}

export default ContactForm
