import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'
import DemoSelectorStyles from '../styles/DemoSelectorStyles'
import NumberedRow from './Layout/NumberedRow'
import ReactCountryFlag from 'react-country-flag'
import styled from 'styled-components'
import { BgImage } from 'gbimage-bridge'
import { getImage } from 'gatsby-plugin-image'

/**
 * @returns {JSX.Element}
 * @constructor
 */
const CountrySelector = () => {
    const { image } = useStaticQuery(graphql`
        query {
            image: file(relativePath: { eq: "country_select_BG.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 3840
                        height: 500
                        placeholder: BLURRED
                        quality: 80
                        transformOptions: { cropFocus: CENTER, fit: OUTSIDE }
                    )
                }
            }
        }
    `)

    const bgImage = getImage(image.childImageSharp)

    return (
        <DemoSelectorStyles>
            <section className='bg-light'>
                <BgImage Tag='div' className='container pt-3 pb-5' image={bgImage}>
                    <NumberedRow number={2} numberContainerClassName='bg-light '>
                        <h2 className='h1 mb-4'>Choose demo country</h2>

                        <div className='d-grid gap-2 d-md-block'>
                            <SelectorLink to={'/'} name={'Finland'} countryCode='FI' active />
                            <SelectorLink to={'/se/'} name={'Sweden'} countryCode='SE' disabled />
                            <SelectorLink to={'/gb/'} name={'United Kingdom'} countryCode='GB' disabled />
                        </div>
                    </NumberedRow>
                </BgImage>
            </section>
        </DemoSelectorStyles>
    )
}

const SelectorLink = ({ to, name, countryCode, active = false, disabled = false }) => (
    <StyledDiv className='d-inline-block bg-light me-3 mb-2'>
        <Link
            to={to}
            className={`btn btn-outline-dark fs-6 rounded-0 spaced-light ps-4 pe-5 w-100 ${active && 'active'} ${
                disabled && 'disabled'
            }`}>
            <ReactCountryFlag
                className='ms-3 me-1'
                style={{ width: '1.1rem', height: '1.1rem', marginBottom: '3px' }}
                countryCode={countryCode}
                svg
                title={countryCode}
            />{' '}
            {name}
        </Link>
    </StyledDiv>
)

export default CountrySelector

const StyledDiv = styled.div`
    a.btn-outline-dark.disabled {
        opacity: 0.4;
    }
`
