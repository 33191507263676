import React from 'react'
import { Container } from 'react-bootstrap'
import FaqItem from './FaqItem'
import NumberedRow from './Layout/NumberedRow'

const Faq = () => {
    return (
        <section className='py-5'>
            <Container>
                <NumberedRow number={3}>
                    <h2 className='h1 mb-5'>Frequently asked questions</h2>
                    <FaqItem
                        question='What is PinkPay™?'
                        answer="The trial payment flow is a payment service that allows customers to try products before purchasing. Flow makes it easier for customers to discover excellent services without the risk of making huge mistakes with their finances. Payment will be charged to the customer's phone bill."
                    />
                    <FaqItem
                        question='What are the benefits of using this?'
                        answer="Subscription payments are an excellent way for businesses to increase their customer's lifetime value (CLV). The trial phase will cause the end-user conversion rates to skyrocket!"
                    />
                    <FaqItem
                        question='Can the customer cancel the subscription?'
                        answer='End-user receives a reorder notification by SMS. The message includes a link to cancel the subscription.'
                    />

                    <FaqItem
                        question='What do we need to know about the local regulation?'
                        answer={
                            <>
                                <p>Local regulation demands the following:</p>
                                <ol>
                                    <li>Proper age-restricted warnings must be given before showing the content</li>
                                    <li>There must be an SMS notification for the user before payment is activated</li>
                                    <li>The subscription needs to have an easy way of cancellation</li>
                                </ol>
                            </>
                        }
                    />
                    <FaqItem
                        question='How much does this service cost?'
                        answer='There is a monthly fee of 99€ per country. Transaction cost is varies based on volume — more information on commercial terms.'
                    />
                    <FaqItem
                        question='How can I make a refund to a customer?'
                        answer='Refunding can be executed via the merchant admin panel. The merchant makes a decision when refunding the customer. The refund can be committed to the end-user either via a bank account or directly to his phone bill, based on customer country. Siru Mobile will take care of the transactions.'
                    />
                    <FaqItem
                        question='How to make the integration?'
                        answer='There is an API. With the API, a merchant can start using the trial payment flow. There is also a sandbox environment available. You also have direct access to the developer team via chat, Skype or e-mail.'
                    />
                    <FaqItem
                        question='Terms & Conditions - is there any small printed text?'
                        answer='Nope, Siru Mobile contracts are straightforward.'
                    />
                </NumberedRow>
            </Container>
        </section>
    )
}

export default Faq
